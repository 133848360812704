import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  TextField,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const GradeDropdownPanel = ({
  grades,
  offers,
  onGradesChange,
  onTotalPriceChange,
}) => {
  const [checkedGrades, setCheckedGrades] = useState({});
  const [cardsPerGrade, setCardsPerGrade] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [openInputs, setOpenInputs] = useState({});
  const sortedOffers = offers.sort((a, b) => b.cards_count - a.cards_count);

  useEffect(() => {
    let selectedGrades = [];
    Object.keys(cardsPerGrade).forEach((gradeId) => {
      const numberOfCards = cardsPerGrade[gradeId];
      const selectedGrade = grades.find(
        (grade) => grade.id === parseInt(gradeId)
      );
      if (numberOfCards && selectedGrade) {
        selectedGrades.push({
          grade_id: selectedGrade.id,
          count: numberOfCards,
        });
      }
    });
    if (onGradesChange) {
      onGradesChange(selectedGrades);
    }
  }, [cardsPerGrade, grades, onGradesChange]);

  const handleCheckboxChange = (gradeId) => {
    setCheckedGrades((prev) => ({
      ...prev,
      [gradeId]: !prev[gradeId],
    }));
    setOpenInputs((prev) => ({
      ...prev,
      [gradeId]: !prev[gradeId],
    }));
    setCardsPerGrade((prevCards) => ({
      ...prevCards,
      [gradeId]: 0,
    }));
  };

  const handleNbCardsChange = (gradeId, event) => {
    const numberOfCards = parseInt(event.target.value, 10);
    setCardsPerGrade((prevCards) => ({
      ...prevCards,
      [gradeId]: numberOfCards,
    }));
  };

  useEffect(() => {
    let calculatedTotalPrice = 0;
    let typeMidCount = 0;
    let typeHighCount = 0;

    Object.keys(cardsPerGrade).forEach((gradeId) => {
      const numberOfCards = cardsPerGrade[gradeId];
      const selectedGrade = grades.find(
        (grade) => grade.id === parseInt(gradeId)
      );

      if (!isNaN(numberOfCards) && numberOfCards > 0 && selectedGrade) {
        if (selectedGrade.class_type === 32) {
          typeMidCount += numberOfCards;
        } else {
          typeHighCount += numberOfCards;
        }
      }
    });

    const enteredCount = typeMidCount + typeHighCount;

    const bestOffer =
      sortedOffers.find((offer) => offer.cards_count === enteredCount) ||
      sortedOffers.find((offer) => enteredCount >= offer.cards_count) ||
      sortedOffers[sortedOffers.length - 1];

    const pricePerCardType1 = bestOffer.price / bestOffer.cards_count;
    const pricePerCardType2 = bestOffer.second_price / bestOffer.cards_count;

    Object.keys(cardsPerGrade).forEach((gradeId) => {
      const numberOfCards = cardsPerGrade[gradeId];
      const selectedGrade = grades.find(
        (grade) => grade.id === parseInt(gradeId)
      );

      if (!isNaN(numberOfCards) && numberOfCards > 0 && selectedGrade) {
        const type = selectedGrade.class_type;
        if (type === 33) {
          calculatedTotalPrice += pricePerCardType2 * numberOfCards;
        } else {
          calculatedTotalPrice += pricePerCardType1 * numberOfCards;
        }
      }
    });

    setTotalPrice(calculatedTotalPrice);
    onTotalPriceChange(calculatedTotalPrice);
  }, [cardsPerGrade, offers, grades, sortedOffers, onTotalPriceChange]);

  return (
    <Accordion
      sx={{
        borderRadius: "22px",
        border: "1px solid #304FFE",
        marginBottom: "15px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: "#f3f4f6",
          borderRadius: "22px",
        }}
      >
        <Typography>عدد البطاقات</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {grades.map((grade) => (
          <Box
            key={grade.id}
            sx={{
              marginBottom: "15px",
              width: "100%",
              borderRadius: "22px",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleCheckboxChange(grade.id)} // Toggle checkbox state and input visibility
            onChange={(event) => handleNbCardsChange(grade.id, event)}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Checkbox
                checked={checkedGrades[grade.id] || false}
                onChange={() => handleCheckboxChange(grade.id)}
                sx={{ marginLeft: "auto" }}
                onClick={(e) => e.stopPropagation()} // Prevent click from affecting the parent
              />
              <Typography sx={{ flexGrow: 1, textAlign: "right" }}>
                {grade.title}
              </Typography>
            </Box>
            {openInputs[grade.id] && (
              <TextField
                label="عدد البطاقات"
                name={`numberOfCards-${grade.id}`}
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  m: 1,
                  borderRadius: "22px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "22px",
                    "& fieldset": {
                      borderColor: "#304FFE",
                    },
                    "&:hover fieldset": {
                      borderColor: "#304FFE",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#304FFE",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormatListNumberedIcon />
                    </InputAdornment>
                  ),
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click from closing the box
                onChange={(event) => handleNbCardsChange(grade.id, event)}
              />
            )}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default GradeDropdownPanel;
