const HomeOfferCard = ({ image, title , label,label2}) => {
    return (
      <div dir="rtl" className="h-44 w-20 md:h-60 md:w-40 gradient-border rounded-2xl">
        <div className="w-full h-full flex flex-col">
          <div className="w-full h-[45%]">
            <img src={image} alt={title} className="h-full w-full object-cover rounded-t-2xl" />
          </div>
          <div className="h-full bg-white flex flex-col items-center justify-center rounded-b-2xl p-1">
  <p className="text-black text-center text-[10px] md:text-base font-bold">{title}</p>
  <p className="text-black text-center text-[10px] md:text-base font-bold">{label}</p>
  <p className="text-black text-center text-[10px] md:text-base font-bold">{label2}</p>
</div>
        </div>
      </div>
    );
  };
  
  export default HomeOfferCard;
  