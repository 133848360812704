import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import MyLearninCourse6Card from "../MyLearninCourse6Card";
import "./MyLearning.css";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import RedeemCode from "./RedeemCode";
import TextTR from "../HomePage/TextTR";
import { useSelector, useDispatch } from "react-redux";
import { setNotificationDrawer } from "../../store/CheckLogin";
import { URL } from "../../static/data/config";
const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  const hourLabel = hours === 1 ? 'hr' : 'hrs';
  const minuteLabel = minutes === 1 ? 'min' : 'mins';

  return `${hours.toString().padStart(2, "0")} ${hourLabel} & ${minutes
    .toString()
    .padStart(2, "0")} ${minuteLabel}`;
};
function MyLearning(props) {


  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const { unlockPowerOfStudySectionProps } = props;

  //const image_url = "http://d2lb7gsppnsll7.cloudfront.net/schooltube/uploads/";
  const [gradeCards, setGradeCards] = useState([]);
  const dispatch = useDispatch();
  const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);
  const [isLoading, setIsLoading] = useState(true);
  const [formattedTime, setFormattedTime] = useState(formatTime(loginData?.user?.time_spent));

  function getData() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGradeCards(res);
        setIsLoading(false);
        
      })
      .catch((err) => console.log(err));
  }

  function handleReddemStatus() {
    getData();
  }
  

  //console.log(gradeCards);
  const localLoginToken =
    !!localStorage.getItem("login-token") ||
    !!sessionStorage.getItem("login-token");
  const reduxLogin = useSelector((state) => state.checkLogin.isLoggedIn);
  const [loginStatus, setLoginStatus] = useState(localLoginToken || reduxLogin);
  const { user, isLoggedIn } = useSelector((state) => state.checkLogin);
  useEffect(() => {
    if (homeDataGlobal) {
      setGradeCards(homeDataGlobal);
      setIsLoading(false);
    } else {
      getData();
    }
  }, []);

  useEffect(() => {
    if (localLoginToken || reduxLogin) {
      setLoginStatus(true);
      getData();
    }
  }, [localLoginToken, reduxLogin]);

  /*getting the categ items */
  const categData = [];
  {
    gradeCards.categories &&
      gradeCards.categories.map((categItem) => {
        categData.push(categItem);
      });
  }

  /**empty login-token  */
  useEffect(() => {
    if (gradeCards && gradeCards.logged_out == "true") {
      localStorage.setItem("login-token", null);
    }
  }, [gradeCards]);

  

//  const userTime = loginData?.user?.time_spent; 

  return (
    <div
      className="my-learning-container"
      onClick={() => {
        dispatch(setNotificationDrawer());
      }}
    >
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>My Course</title>
        <meta
          name="description"
          content={`Check your Courses in an easy and effiecient way `}
        />
      </Helmet>
      <div className="container-center-horizontal">
        <div className="my-learning screen my-learning-page">
          <div
            className="overlap-group1-2 min-h-screen"
            style={{ backgroundImage: "url(./img/bg-image-tr.png)" }}
          >
            <div className="my-learning-title-section">
              <div className="title-redeam-section my-learning-title-section-container">
                <div className="!mt-[3rem] valign-text-middle poppins-semi-bold-black-25px">
                  <TextTR text="lbl_my_learning2" />
                </div>
                {
                  <div className="time-spent-content">
                    {formattedTime && isLoggedIn ? (
                      <h5 className="!mb-[3rem] sm:!mb-0">
                        Time Spent: <b>{localStorage.getItem("timeSpent")}</b>
                      </h5>
                    ) : (
                      <h5>Please Login!</h5>
                    )}
                  </div>
                }
                <RedeemCode url={URL} onRedeem={handleReddemStatus} />
              </div>
              <TeacherTitleLines className="myLearning-title-lines" />
            </div>
            <div className={"grade-cards-container !mb-[50px] !justify-center"}>
              {loginStatus ? (
                isLoading ? (
                  <div className="loading-div">
                    <img src="./img/all-grade-loading.gif" alt="Loading" />
                  </div>
                ) : gradeCards.grades.length > 0 &&
                  gradeCards.grades.some(
                    (gradeCard) =>
                      gradeCard.material_access || gradeCard.grade_access
                  ) ? (
                  gradeCards.grades.map((gradeCard, index) => {
                    const gradeTitle = gradeCard.title;
                    let card_img = gradeCard.image;
                    if (gradeCard.image == null) {
                      card_img = "./img/grade-image@2x.png";
                    }
                    if (gradeCard.material_access || gradeCard.grade_access) {
                      return (
                        <Link
                          key={index}
                          to={{
                            pathname: `/watch-course`,
                            search: `id=${gradeCard.id}`,
                            state: { categData, gradeTitle },
                          }}
                        >
                          <MyLearninCourse6Card
                            gradeLevelImg={gradeTitle}
                            gradeLevelText={gradeTitle}
                            bgimage={card_img}
                            materialsCount={gradeCard?.materials.length}
                            videoCount={gradeCard.videos_count}
                            pdfCount={gradeCard.pdfs_count}
                            quizesCount={gradeCard.quizzes_count}
                            access_expiry_date={gradeCard.access_expiry_date}
                            displayExp={1}
                          />
                        </Link>
                      );
                    }
                  })
                ) : (
                  <h1
                    style={{
                      color: "#ccc",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Grades Available at the moment please try again later
                  </h1>
                )
              ) : (
                <h1
                  style={{ color: "#000", width: "100%", textAlign: "center" }}
                >
                  Please Login!!
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <UnlockPowerOfStudySection
        group148={unlockPowerOfStudySectionProps.group148}
        group147={unlockPowerOfStudySectionProps.group147}
      />
    </div>
  );
}

export default MyLearning;
