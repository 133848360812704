import "./App.css";
import React from "react";
import { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import AskAQuestion from "./components/AskAQuestion";
import AccountSetting from "./components/AccountSetting/AccountSetting";
import Teachers from "./components/Teachers";
import TeachersDetails from "./components/TeachersDetails";
import HomePage from "./components/HomePage";
import MyLearning from "./components/MyLearning";
import InsideMyLearning from "./components/InsideMyLearning";
import AllGrades from "./components/AllGrades";
import InsideGrade from "./components/InsideGrade";
import IntroductionVideoMyLearningMoreSect from "./components/IntroductionVideoMyLearningMoreSect";
import BuyCoursePopup from "./components/BuyCoursePopup";
import Quizes from "./components/Quizes";
import ContactUS from "./components/ContactUs/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./components/AboutUs/AboutUs";
import EditProfile from "./components/AccountSetting/EditProfile";
import ChangeProfilePwd from "./components/AccountSetting/ChangeProfilePwd";
import SearchPage from "./components/SearchPage/SearchPage";
import { useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import SiteMapResponse from "./components/getSiteMap/SiteMapResponse";
import Navbar from "./components/Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { translationAction } from "./store/tr-slice";
import MobileBottomNav from "./components/Navbar/MobileBottomNav";
import { login, logout, setTableName } from "./store/CheckLogin";
import Footer from "./components/Footer";
// new
import {
  setCities,
  setCountries,
  setUnReadNotifications,
} from "./store/CheckLogin";
import { setHomeDataGlobal, setDataFetched } from "./store/DataFetched";
import SelectLanguage from "./components/Language/SelectLanguage";
import { URL } from "./static/data/config";
import PaymentMethod from "./components/GetCardButton/PaymentMethod";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return <Element name="top" />;
}

/**to format the date and the time */
function formatDate(date) {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

function App() {
  const { isLoggedIn } = useSelector((state) => state.checkLogin);

  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");

  const dispatch = useDispatch();
  const sc_local = localStorage.getItem("lang-shortcut") ?? "en";

  let sc;
  if (!sc_local || sc_local == "null") {
    sc = navigator.language.split("-")[0];
  } else {
    sc = sc_local;
  }
  if (sc) {
    dispatch(translationAction.setShortcut(sc));
    // console.log("i set the sc to the redux as: ", sc);
  } else {
    dispatch(translationAction.setShortcut("en"));
    // console.log("i set the sc to the redux as 'en'");
  }

  /**fetching translation */
  function checkLocalStorage(key) {
    return localStorage.hasOwnProperty(key);
  }

  // Example usage
  var keyExists = checkLocalStorage("auto-login");

  function getTranslation() {
    let formData = new FormData();
    var date = localStorage.getItem("tr_modif_date");
    if (!date) {
      date = "1970-01-01 00:00:00";
    }
    formData.append("date", date);

    const url1 = `https://stdev.beeflex.net/master/translations`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if (!res.is_updated) {
          var date = res.date;
          var list = res.list;

          localStorage.setItem("tr_modif_date", date);
          localStorage.setItem("tr_list", JSON.stringify(list));
        } else {
          var list = localStorage.getItem("tr_list");
        }
      })
      .catch((err) => console.log(err));

    dispatch(translationAction.setTranslation(localStorage.getItem("tr_list")));
  }
  useEffect(() => {
    getTranslation();
    if (keyExists) {
      // console.log("exist");
    } else {
      localStorage.setItem("auto-login", null);
      // console.log("it doesnt exist ");
    }
  }, []);

  /**end of trnaslation */
  const selectorLoginCheck = useSelector(
    (state) => state.checkLogin.isLoggedIn
  );
  const [accessRedux, setAccessRedux] = useState(selectorLoginCheck);
  useEffect(() => {
    setAccessRedux(selectorLoginCheck);
  }, [accessRedux]);

  useEffect(() => {
    if (!isLoggedIn) {
      let formData = new FormData();
      formData.append("token", loginToken);
      formData.append("is_web", true);
      const url1 = `${URL}users/auto-login`;
      fetch(url1, {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then((res) => {
          if (res.succeeded) {
            dispatch(login(res.user));
            dispatch(setTableName(res.table_name));
            localStorage.setItem("enterTime", formatDate(new Date()));
          } else {
            dispatch(logout());
          }
        })
        .catch((err) => console.log(err));
    } else {
      return;
    }
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width:1024px)" });

  // new
  const [getHomeData, setHomeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function getData() {
    setIsLoading(true);
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        dispatch(setUnReadNotifications(res.not_seen_notifs));
        setHomeData(res);
        dispatch(setCities(res.cities));
        dispatch(setCountries(res.countries));
        dispatch(setHomeDataGlobal(res));
        dispatch(setDataFetched());
        localStorage.setItem("offers", JSON.stringify(res.offers));
        localStorage.setItem("grades", JSON.stringify(res.grades));
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  // get the time spent when the user shut down the device
  function getTimeSpent() {
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("active_time", localStorage.getItem("enterTime"));
    formData.append("inActive_time", formatDate(new Date()));
    const url1 = `${URL}users/set-time-spent`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData();
    const handleBeforeUnload = (event) => {
      // Call your function when the page is about to be unloaded
      getTimeSpent();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        getTimeSpent();
      } else if (document.visibilityState === "visible") {
        localStorage.setItem("enterTime", formatDate(new Date()));
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    const hourLabel = hours === 1 ? 'hr' : 'hrs';
    const minuteLabel = minutes === 1 ? 'min' : 'mins';
  
    return `${hours.toString().padStart(2, "0")} ${hourLabel} & ${minutes
      .toString()
      .padStart(2, "0")} ${minuteLabel}`;
  };
const getTime = () => {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ?? sessionStorage.getItem("login-token")
    );
    const url1 = `${URL}users/update-time-spent`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if (res.succeeded) {
          const timeSpentInSeconds = res.time_spent;
          const formattedTime = formatTime(timeSpentInSeconds);
          console.log(timeSpentInSeconds);
          localStorage.setItem("timeSpent", formattedTime);
        } else {
          console.log('API call did not succeed:', res.message);
        }
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    // Function to handle initial load and setting the interval
    const initiateApiCall = () => {
      const intervalId = setInterval(() => {
        getTime();
      }, 60000); // 60000ms = 1 minute

      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    };

    const cleanup = initiateApiCall();
    return cleanup;
  }, []);
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar
          getHomeData={getHomeData}
          accessRedux={accessRedux}
          setAccessRedux={setAccessRedux}
        />
        <Switch>
          <Route path="/search">
            <SearchPage />
          </Route>
          <Route path="/ask-a-question">
            <AskAQuestion
              askYourQuestion="Ask your question"
              writeYourQuestion="Write your question"
              imageIcon="static/img/image-icon@2x.png"
              uploadImage="Upload image"
              submitQuestion="Submit Question"
            />
          </Route>
          <Route path="/account-setting">
            <AccountSetting {...accountSettingData} />
          </Route>
          <Route path="/teachers">
            <Teachers {...teachersData} />
          </Route>
          <Route path="/teachers-details">
            <TeachersDetails {...teachersDetailsData} />
          </Route>
          <Route path="/:path(|home-page)">
            <HomePage
              {...homePageData}
              getHomeData={getHomeData}
              isLoading={isLoading}
            />
          </Route>
          <Route path="/contact-us">
            <ContactUS />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/about-us">
            <AboutUs getAboutUsData={getHomeData} isLoading={isLoading} />
          </Route>
          <Route path="/my-learning">
            <MyLearning {...myLearningData} />
          </Route>
          <Route path="/inside-my-learning">
            <InsideMyLearning {...insideMyLearningData} />
          </Route>
          <Route path="/all-grades">
            <AllGrades {...allGradesData} />
          </Route>
          <Route path="/inside-grade">
            <InsideGrade {...insideGradeData} />
          </Route>
          <Route path="/watch-course">
            <IntroductionVideoMyLearningMoreSect
              {...introductionVideoMyLearningMoreSectData}
            />
          </Route>
          <Route path="/buy-course-popup">
            <BuyCoursePopup
              selectYourGrade="Select your Grade"
              addNotes="Add Notes"
              submitRequest="Submit Request"
              buyGradesDropdownProps={buyCoursePopupData.buyGradesDropdownProps}
              cancelBTNProps={buyCoursePopupData.cancelBTNProps}
            />
          </Route>
          <Route path="/quizes/:id">
            <Quizes {...quizesData} />
          </Route>
          <Route exact path="/edit-my-profile">
            <EditProfile />
          </Route>
          <Route path="/change-pwd">
            <ChangeProfilePwd />
          </Route>
          <Route path="/generateSite">
            <SiteMapResponse />
          </Route>
          {/* new */}
          <Route path="/payment-method">
            <PaymentMethod />
          </Route>
        </Switch>
        <Route path="/language">
          <SelectLanguage languages={getHomeData.languages} />
        </Route>
        {isMobile && <MobileBottomNav />}
        <footer>
          <Footer getHomeData={getHomeData} isLoading={isLoading} />
        </footer>
      </Router>
    </div>
  );
}

export default App;
const navBarText1Data = {
  children: "TEACHERS",
};

const navBarText2Data = {
  children: "MY LEARNING",
  className: "my-learning-title-1",
};

const navBarText3Data = {
  children: "ALL GRADES",
  className: "all-grades-title",
};

const navBarText4Data = {
  children: "POINTS OF SALE",
  className: "point-of-sale-title",
};

const navBarText5Data = {
  children: "CONTACT US",
  className: "contact-us-title",
};

const navBarElements1Data = {
  group_37: "static/img/group-31@2x.png",
};

const bTNComponent1Data = {
  children: "Let’s Do It",
};

const swiperComponentData = {
  bTNComponentProps: bTNComponent1Data,
};

const frame1421Data = {
  group_2: "static/img/group@2x.png",
  className: "",
};

const featuredCoursesComponent1Data = {
  frame137: "static/img/frame-137@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 6<br />
      Math
    </React.Fragment>
  ),
  grade6Math_2: "Grade 6 Math",
  frame142Props: frame1421Data,
};

const frame1422Data = {
  group_2: "static/img/group-4@2x.png",
  className: "",
};

const featuredCoursesComponent2Data = {
  frame137: "static/img/frame-137-2@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 8<br />
      Physics
    </React.Fragment>
  ),
  grade6Math_2: "Grade 8 Physics",
  className: "featured-courses-1",
  frame142Props: frame1422Data,
};

const frame1423Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-1-1",
};

const featuredCoursesComponent3Data = {
  frame137: "static/img/frame-137-3@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 9<br />
      Chemistry
    </React.Fragment>
  ),
  grade6Math_2: "Grade 9 Chemistry",
  className: "featured-courses-2-1",
  frame142Props: frame1423Data,
};

const frame1424Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-4",
};

const featuredCoursesComponent4Data = {
  frame137: "static/img/frame-137-4@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 8<br />
      Math
    </React.Fragment>
  ),
  grade6Math_2: "Grade 8 Math",
  className: "featured-courses-1",
  frame142Props: frame1424Data,
};

const frame1425Data = {
  group_2: "static/img/group@2x.png",
  className: "",
};

const featuredCoursesComponent5Data = {
  frame137: "static/img/frame-137-5@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 6<br />
      English
    </React.Fragment>
  ),
  grade6Math_2: "Grade 6 English",
  className: "featured-courses-1",
  frame142Props: frame1425Data,
};

const frame1426Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-5",
};

const featuredCoursesComponent6Data = {
  frame137: "static/img/frame-137-6@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 6<br />
      Math
    </React.Fragment>
  ),
  grade6Math_2: "Grade 6 Math",
  className: "featured-courses-1",
  frame142Props: frame1426Data,
};

const frame1427Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-6",
};

const featuredCoursesComponent7Data = {
  frame137: "static/img/frame-137-7@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 8<br />
      Physics
    </React.Fragment>
  ),
  grade6Math_2: "Grade 8 Physics",
  className: "featured-courses-1",
  frame142Props: frame1427Data,
};

const frame1428Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-1",
};

const featuredCoursesComponent8Data = {
  frame137: "static/img/frame-137-8@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 9<br />
      Chemistry
    </React.Fragment>
  ),
  grade6Math_2: "Grade 9 Chemistry",
  className: "featured-courses-2",
  frame142Props: frame1428Data,
};

const frame1429Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142-7",
};

const featuredCoursesComponent9Data = {
  frame137: "static/img/frame-137-9@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 8<br />
      Math
    </React.Fragment>
  ),
  grade6Math_2: "Grade 8 Math",
  className: "featured-courses-1",
  frame142Props: frame1429Data,
};

const frame14210Data = {
  group_2: "static/img/group@2x.png",
  className: "frame-142",
};

const featuredCoursesComponent10Data = {
  frame137: "static/img/frame-137-10@2x.png",
  grade6Math: (
    <React.Fragment>
      Grade 6<br />
      English
    </React.Fragment>
  ),
  grade6Math_2: "Grade 6 English",
  className: "featured-courses-1",
  frame142Props: frame14210Data,
};

const featuredCoursesSectionData = {
  featuredCoursesComponent1Props: featuredCoursesComponent1Data,
  featuredCoursesComponent2Props: featuredCoursesComponent2Data,
  featuredCoursesComponent3Props: featuredCoursesComponent3Data,
  featuredCoursesComponent4Props: featuredCoursesComponent4Data,
  featuredCoursesComponent5Props: featuredCoursesComponent5Data,
  featuredCoursesComponent6Props: featuredCoursesComponent6Data,
  featuredCoursesComponent7Props: featuredCoursesComponent7Data,
  featuredCoursesComponent8Props: featuredCoursesComponent8Data,
  featuredCoursesComponent9Props: featuredCoursesComponent9Data,
  featuredCoursesComponent10Props: featuredCoursesComponent10Data,
};

const feedbackSwiperComponent22Data = {
  leftArrow: "./img/left-arrow@2x.png",
  feedbackSwiper1: "static/img/feedback-swiper-1@1x.png",
  ellipse51: "static/img/qoutation-2@2x.png",
  qoutation1: "static/img/qoutation-2@2x.png",
  loremIpsumDolorSi1:
    "Lorem ipsum dolor sit amet consectetur. Non sed tincidunt amet interdum. Nec tempus cursus viverra facilisi urna dignissim vel.",
  ellipse52: "static/img/qoutation-2@2x.png",
  qoutation2: "static/img/qoutation-2@2x.png",
  loremIpsumDolorSi2:
    "Lorem ipsum dolor sit amet consectetur. Non sed tincidunt amet interdum. Nec tempus cursus viverra facilisi urna dignissim vel.",
  rightArrow: "./img/right-arrow@2x.png",
  feedbackSwiper2Props: { qoutation: "static/img/qoutation@2x.png" },
  group1431Props: { className: "group-143" },
  group1432Props: { className: "group-143" },
};

const supportedGradesCardImage1Data = {
  spanText2: "9",
};

const frame151Data = {
  children: "English",
};

const frame152Data = {
  children: "Biology",
};

const materialsSlider1Data = {
  frame151Props: frame151Data,
  frame152Props: frame152Data,
};

const supportedGradesCard11Data = {
  grade9_2: "Grade 9",
  language: "English",
  supportedGradesCardImageProps: supportedGradesCardImage1Data,
  materialsSliderProps: materialsSlider1Data,
};

const supportedGradesCardImage2Data = {
  spanText2: "8",
};

const frame153Data = {
  children: "English",
};

const frame154Data = {
  children: "Biology",
};

const materialsSlider2Data = {
  frame151Props: frame153Data,
  frame152Props: frame154Data,
};

const supportedGradesCard12Data = {
  grade9_2: "Grade 8",
  language: "French",
  supportedGradesCardImageProps: supportedGradesCardImage2Data,
  materialsSliderProps: materialsSlider2Data,
};

const supportedGradesCardImage3Data = {
  spanText2: "7",
};

const frame155Data = {
  children: "English",
};

const frame156Data = {
  children: "Biology",
};

const materialsSlider3Data = {
  frame151Props: frame155Data,
  frame152Props: frame156Data,
};

const supportedGradesCard13Data = {
  grade9_2: "Grade 7",
  language: "English",
  supportedGradesCardImageProps: supportedGradesCardImage3Data,
  materialsSliderProps: materialsSlider3Data,
};

const supportedGradesCardImage4Data = {
  spanText2: "6",
};

const frame157Data = {
  children: "English",
};

const frame158Data = {
  children: "Biology",
};

const materialsSlider4Data = {
  frame151Props: frame157Data,
  frame152Props: frame158Data,
};

const supportedGradesCardImage5Data = {
  spanText2: "5",
};

const frame159Data = {
  children: "English",
};

const frame1510Data = {
  children: "Biology",
};

const materialsSlider5Data = {
  frame151Props: frame159Data,
  frame152Props: frame1510Data,
};

const supportedGradesSwiperComponent22Data = {
  grade6_2: "Grade 6",
  group_4: "static/img/qoutation-2@2x.png",
  language1: "English",
  grade5_2: "Grade 5",
  group_5: "static/img/qoutation-2@2x.png",
  language2: "French",
  supportedGradesCard11Props: supportedGradesCard11Data,
  supportedGradesCard12Props: supportedGradesCard12Data,
  supportedGradesCard13Props: supportedGradesCard13Data,
  supportedGradesCardImage1Props: supportedGradesCardImage4Data,
  materialsSlider1Props: materialsSlider4Data,
  supportedGradesCardImage2Props: supportedGradesCardImage5Data,
  materialsSlider2Props: materialsSlider5Data,
};

const bTNComponent2Data = {
  children: "View All Grades",
  className: "read-more-btn-1",
};

const step11Data = {
  x1BuySchooltubeCards: "1. Buy SchoolTube Cards",
  buyOurCardsFromO:
    "Buy our cards from our points of sales (contact us : 71 299 280 WhatsApp).",
};

const step12Data = {
  x1BuySchooltubeCards: "4. You can ask our professional teachers",
  buyOurCardsFromO: "Teachers will answer your questions with details.",
  className: "step-2",
};

const step13Data = {
  x1BuySchooltubeCards: "2. Register / login to your account",
  buyOurCardsFromO: "You need an account to access our paid videos.",
  className: "step-3",
};

const step14Data = {
  x1BuySchooltubeCards: "5. Get answers from teachers",
  buyOurCardsFromO: "Teachers will answer your questions in details.",
  className: "step-4",
};

const step15Data = {
  x1BuySchooltubeCards: "3. Enter the unique numbers",
  buyOurCardsFromO: "Each class has its own cards with unique numbers.",
  className: "step-5",
};

const step16Data = {
  x1BuySchooltubeCards: "6. Search for teachers near to you",
  buyOurCardsFromO: "You can search for teachers and get their contact info.",
  className: "step",
};

const howItWorksSectionWithBGData = {
  overlapGroup2: "static/img/education-pattern-1@1x.png",
  step11Props: step11Data,
  step12Props: step12Data,
  step13Props: step13Data,
  step14Props: step14Data,
  step15Props: step15Data,
  step16Props: step16Data,
};

const unlockPowerOfStudySection1Data = {
  group148: "static/img/group-148@2x.png",
  group147: "static/img/group-147@2x.png",
};

const homePageData = {
  logo1: "static/img/logo@2x.png",
  // overlapGroup1: "static/img/education-pattern@1x.png",
  allGradesSmallTitle: "All Grades",
  supportedGradesTitle: "Supported Grades",
  lebanonFlag: "static/img/lebanon-flag@2x.gif",
  place1: "LEBANON",
  iraqFlag_2: "static/img/iraq-flag-2@2x.gif",
  place2: "IRAQ",
  x962: "static/img/-962@1x.gif",
  logo2: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  navBarText1Props: navBarText1Data,
  navBarText2Props: navBarText2Data,
  navBarText3Props: navBarText3Data,
  navBarText4Props: navBarText4Data,
  navBarText5Props: navBarText5Data,
  navBarElementsProps: navBarElements1Data,
  swiperComponentProps: swiperComponentData,
  featuredCoursesSectionProps: featuredCoursesSectionData,
  feedbackSwiperComponent22Props: feedbackSwiperComponent22Data,
  supportedGradesSwiperComponent22Props: supportedGradesSwiperComponent22Data,
  bTNComponentProps: bTNComponent2Data,
  howItWorksSectionWithBGProps: howItWorksSectionWithBGData,
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection1Data,
};
const unlockPowerOfStudySection2Data = {
  group148: "static/img/group-148-1@2x.png",
  group147: "static/img/group-147-1@2x.png",
  className: "unlock-power-of-study-section-1",
};

const gradeImage1Data = {
  grade9: "Grade 9",
};

const myLearninCourse6Card1Data = {
  grade9: "Grade 9 - French",
  gradeImageProps: gradeImage1Data,
};

const gradeImage2Data = {
  grade9: "Grade 5",
};

const myLearninCourse6Card2Data = {
  grade9: "Grade 5 - French",
  className: "my-learnin-course-6-card-1",
  gradeImageProps: gradeImage2Data,
};

const gradeImage3Data = {
  grade9: "Grade 6",
};

const myLearninCourse6Card3Data = {
  grade9: "Grade 6 - English",
  className: "my-learnin-course-5-card",
  gradeImageProps: gradeImage3Data,
};

const gradeImage4Data = {
  grade9: "Grade 10",
};

const myLearninCourse6Card4Data = {
  grade9: "Grade 10 - English",
  className: "my-learnin-course-4-card",
  gradeImageProps: gradeImage4Data,
};

const gradeImage5Data = {
  grade9: "Grade 8",
};

const myLearninCourse6Card5Data = {
  grade9: "Grade 8 - English",
  className: "my-learnin-course-3-card",
  gradeImageProps: gradeImage5Data,
};

const gradeImage6Data = {
  grade9: "Grade 7",
};

const myLearninCourse6Card6Data = {
  grade9: "Grade 7 - English",
  className: "my-learnin-course-2-card",
  gradeImageProps: gradeImage6Data,
};

const gradeImage7Data = {
  grade9: "Grade 9",
};

const navBarText6Data = {
  children: "TEACHERS",
};

const navBarText7Data = {
  children: "MY LEARNING",
  className: "my-learning-title-3",
};

const navBarText8Data = {
  children: "ALL GRADES",
  className: "all-grades-title-1",
};

const navBarText9Data = {
  children: "POINTS OF SALE",
  className: "point-of-sale-title-1",
};

const navBarText10Data = {
  children: "CONTACT US",
  className: "contact-us-title-1",
};

const navBarTitles1Data = {
  navBarText1Props: navBarText6Data,
  navBarText2Props: navBarText7Data,
  navBarText3Props: navBarText8Data,
  navBarText4Props: navBarText9Data,
  navBarText5Props: navBarText10Data,
};

const navBarElements3Data = {
  group_37: "static/img/group-31@2x.png",
  className: "language-1",
};

const accountSettingData = {
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection1Data,
};

const teachersData = {
  logo1: "static/img/logo@2x.png",
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection1Data,
  navBarTitlesProps: navBarTitles1Data,
};

const myLearningData = {
  logo1: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons-1@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  myLearning: "My Learning",
  grade9English: "Grade 9 - English",
  ticketIcon: "static/img/ticket-icon@2x.png",
  enterRedeemCode: "Enter Redeem Code",
  redeem: "Redeem",
  logo2: "static/img/logo@2x.png",
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection2Data,
  myLearninCourse6Card1Props: myLearninCourse6Card1Data,
  myLearninCourse6Card2Props: myLearninCourse6Card2Data,
  myLearninCourse6Card3Props: myLearninCourse6Card3Data,
  myLearninCourse6Card4Props: myLearninCourse6Card4Data,
  myLearninCourse6Card5Props: myLearninCourse6Card5Data,
  myLearninCourse6Card6Props: myLearninCourse6Card6Data,
  gradeImageProps: gradeImage7Data,
  navBarTitlesProps: navBarTitles1Data,
  navBarElementsProps: navBarElements3Data,
};

const frame1522Data = {
  unit1: "Unit 1",
  address: "3 Chapters",
};

const frame1523Data = {
  unit1: "Unit 2",
  address: "3 Chapters",
  className: "frame-154",
};

const frame1524Data = {
  unit1: "Unit 3",
  address: "4 Chapters",
  className: "frame-153",
};

const videosDropdownForMyLearning1Data = {
  frame1521Props: frame1522Data,
  frame1522Props: frame1523Data,
  frame1523Props: frame1524Data,
};

const unitsDropDown1Data = {
  videosDropdownForMyLearningProps: videosDropdownForMyLearning1Data,
};

const bTNComponent24Data = {
  className: "read-more-btn-4",
};

const unlockPowerOfStudySection22Data = {
  group147: "static/img/group-147-1@2x.png",
  bTNComponent2Props: bTNComponent24Data,
};

const biologyMaterialBTN1Data = {
  children: "Biology",
};

const biologyMaterialBTN2Data = {
  children: "English",
};

const detailsGroup32Data = {
  group1: "static/img/group-79@2x.png",
};

const bTNComponent31Data = {
  children: "Continue Study",
};

const navBarText11Data = {
  children: "TEACHERS",
};

const navBarText12Data = {
  children: "MY LEARNING",
  className: "my-learning-title-5",
};

const navBarText13Data = {
  children: "ALL GRADES",
  className: "all-grades-title-2",
};

const navBarText14Data = {
  children: "POINTS OF SALE",
  className: "point-of-sale-title-2",
};

const navBarText15Data = {
  children: "CONTACT US",
  className: "contact-us-title-2",
};

const navBarTitles2Data = {
  navBarText1Props: navBarText11Data,
  navBarText2Props: navBarText12Data,
  navBarText3Props: navBarText13Data,
  navBarText4Props: navBarText14Data,
  navBarText5Props: navBarText15Data,
};

const searchField3Data = {
  className: "search-field-2",
};

const navBarElements4Data = {
  group_37: "static/img/group-82@2x.png",
  className: "language-2",
};

const insideMyLearningData = {
  logo1: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons-1@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  insideGradeTopBanner: "static/img/inside-grade-top-banner@1x.png",
  grade9English: "Grade 9 English",
  chemistry: "Chemistry",
  math: "Math",
  physics: "Physics",
  grade8EnglishTitle: "Grade 9 - English",
  logo2: "static/img/logo@2x.png",
  unitsDropDownProps: unitsDropDown1Data,
  unlockPowerOfStudySection2Props: unlockPowerOfStudySection22Data,
  biologyMaterialBTN1Props: biologyMaterialBTN1Data,
  biologyMaterialBTN2Props: biologyMaterialBTN2Data,
  detailsGroup3Props: detailsGroup32Data,
  bTNComponent3Props: bTNComponent31Data,
  navBarTitlesProps: navBarTitles2Data,
  searchFieldProps: searchField3Data,
  navBarElementsProps: navBarElements4Data,
};

const gradeImage8Data = {
  grade9: "Grade 9",
};

const myLearninCourse6Card7Data = {
  grade9: "Grade 9 - French",
  gradeImageProps: gradeImage8Data,
};

const gradeImage9Data = {
  grade9: "Grade 5",
};

const myLearninCourse6Card8Data = {
  grade9: "Grade 5 - French",
  className: "my-learnin-course-6-card-3",
  gradeImageProps: gradeImage9Data,
};

const gradeImage10Data = {
  grade9: "Grade 6",
};

const myLearninCourse6Card9Data = {
  grade9: "Grade 6 - English",
  className: "my-learnin-course-5-card-1",
  gradeImageProps: gradeImage10Data,
};

const gradeImage11Data = {
  grade9: "Grade 10",
};

const myLearninCourse6Card10Data = {
  grade9: "Grade 10 - English",
  className: "my-learnin-course-4-card-1",
  gradeImageProps: gradeImage11Data,
};

const gradeImage12Data = {
  grade9: "Grade 8",
};

const myLearninCourse6Card11Data = {
  grade9: "Grade 8 - English",
  className: "my-learnin-course-3-card-1",
  gradeImageProps: gradeImage12Data,
};

const gradeImage13Data = {
  grade9: "Grade 7",
};

const myLearninCourse6Card12Data = {
  grade9: "Grade 7 - English",
  className: "my-learnin-course-2-card-1",
  gradeImageProps: gradeImage13Data,
};

const gradeImage14Data = {
  grade9: "Grade 9",
};

const footerData = {
  socialMediaIcons: "static/img/social-media-icons-2@2x.png",
};

const unlockPowerOfStudySection3Data = {
  group148: "static/img/group-148-1@2x.png",
  group147: "static/img/group-147-2@2x.png",
  className: "unlock-power-of-study-section-2",
};

const customersDropDown1Data = {
  place1: "LEBANON",
  place2: "LEBANON",
  place3: "IRAQ",
};

const customersDropDown2Data = {
  place1: "ENGLISH",
  place2: "ENGLISH",
  place3: "FRENCH",
  className: "language-drop-down",
};

const navBarText16Data = {
  children: "TEACHERS",
};

const navBarText17Data = {
  children: "MY LEARNING",
  className: "my-learning-title-7",
};

const navBarText18Data = {
  children: "ALL GRADES",
  className: "all-grades-title-3",
};

const navBarText19Data = {
  children: "POINTS OF SALE",
  className: "point-of-sale-title-3",
};

const navBarText20Data = {
  children: "CONTACT US",
  className: "contact-us-title-3",
};

const navBarTitles3Data = {
  navBarText1Props: navBarText16Data,
  navBarText2Props: navBarText17Data,
  navBarText3Props: navBarText18Data,
  navBarText4Props: navBarText19Data,
  navBarText5Props: navBarText20Data,
};

const navBarElements5Data = {
  group_37: "static/img/group-77@2x.png",
  className: "language-3",
};

const allGradesData = {
  allGrades: "All Grades",
  grade9English: "Grade 9 - English",
  logo: "static/img/logo@2x.png",
  myLearninCourse6Card1Props: myLearninCourse6Card7Data,
  myLearninCourse6Card2Props: myLearninCourse6Card8Data,
  myLearninCourse6Card3Props: myLearninCourse6Card9Data,
  myLearninCourse6Card4Props: myLearninCourse6Card10Data,
  myLearninCourse6Card5Props: myLearninCourse6Card11Data,
  myLearninCourse6Card6Props: myLearninCourse6Card12Data,
  gradeImageProps: gradeImage14Data,
  footerProps: footerData,
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection3Data,
  customersDropDown1Props: customersDropDown1Data,
  customersDropDown2Props: customersDropDown2Data,
  navBarTitlesProps: navBarTitles3Data,
  navBarElementsProps: navBarElements5Data,
};

const frame1525Data = {
  unit1: "Unit 1",
  address: "3 Chapters",
};

const frame1526Data = {
  unit1: "Unit 2",
  address: "3 Chapters",
  className: "unit-2-1",
};

const frame1527Data = {
  unit1: "Unit 3",
  address: "4 Chapters",
  className: "unit-3",
};

const videosDropdownForMyLearning2Data = {
  frame1521Props: frame1525Data,
  frame1522Props: frame1526Data,
  frame1523Props: frame1527Data,
};

const unitsDropDown2Data = {
  videosDropdownForMyLearningProps: videosDropdownForMyLearning2Data,
};

const bTNComponent26Data = {
  className: "read-more-btn-6",
};

const unlockPowerOfStudySection23Data = {
  group147: "static/img/group-147-2@2x.png",
  bTNComponent2Props: bTNComponent26Data,
};

const biologyMaterialBTN22Data = {
  children: "Biology",
};

const biologyMaterialBTN23Data = {
  children: "English",
};

const detailsGroup33Data = {
  group1: "static/img/group-84@2x.png",
};

const bTNComponent32Data = {
  children: "Get Code",
};

const navBarText21Data = {
  children: "TEACHERS",
};

const navBarText22Data = {
  children: "MY LEARNING",
  className: "my-learning-title-9",
};

const navBarText23Data = {
  children: "ALL GRADES",
  className: "all-grades-title-4",
};

const navBarText24Data = {
  children: "POINTS OF SALE",
  className: "point-of-sale-title-4",
};

const navBarText25Data = {
  children: "CONTACT US",
  className: "contact-us-title-4",
};

const teachersDetailsData = {
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection1Data,
  navBarTitlesProps: navBarTitles1Data,
};

const navBarTitles4Data = {
  navBarText1Props: navBarText21Data,
  navBarText2Props: navBarText22Data,
  navBarText3Props: navBarText23Data,
  navBarText4Props: navBarText24Data,
  navBarText5Props: navBarText25Data,
};

const navBarElements6Data = {
  group_37: "static/img/group-77@2x.png",
  className: "language-4",
};

const insideGradeData = {
  logo1: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons-2@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  insideGradeTopBanner: "static/img/inside-grade-top-banner@1x.png",
  grade9English: "Grade 9 English",
  chemistry: "Chemistry",
  math: "Math",
  physics: "Physics",
  grade8EnglishTitle: "Grade 9 - English",
  logo2: "static/img/logo@2x.png",
  unitsDropDownProps: unitsDropDown2Data,
  unlockPowerOfStudySection2Props: unlockPowerOfStudySection23Data,
  biologyMaterialBTN21Props: biologyMaterialBTN22Data,
  biologyMaterialBTN22Props: biologyMaterialBTN23Data,
  detailsGroup3Props: detailsGroup33Data,
  bTNComponent3Props: bTNComponent32Data,
  navBarTitlesProps: navBarTitles4Data,
  navBarElementsProps: navBarElements6Data,
};

const frame15222Data = {
  unit1: "Unit 1",
  address: "3 Chapters",
};

const baseCheckbox3Data = {
  className: "base-checkbox-3",
};

const baseCheckbox4Data = {
  className: "base-checkbox-4",
};

const baseCheckbox5Data = {
  className: "base-checkbox-5",
};

const frame15223Data = {
  unit1: "Unit 2",
  address: "3 Chapters",
  className: "frame-15",
};

const frame15224Data = {
  unit1: "Unit 3",
  address: "4 Chapters",
  className: "frame-15",
};

const group311Data = {
  frame15221Props: frame15223Data,
  frame15222Props: frame15224Data,
};

const smallVideosDropdownForMyLearningClaData = {
  chapter1: "Chapter 1",
  address1: "3 videos",
  number1: "2",
  frame3231: "static/img/frame-323@2x.png",
  group1: "static/img/group-90@2x.png",
  videoLesson2SecondLineTitle: (
    <React.Fragment>
      Video Lesson 2<br />
      Second line title
    </React.Fragment>
  ),
  group2: "static/img/group-91@2x.png",
  x0600Mins: "06:00 mins",
  group3: "static/img/group-89@2x.png",
  number2: "3",
  frame3232: "static/img/frame-323@2x.png",
  group4: "static/img/group-90@2x.png",
  videoLesson3: "Video Lesson 3",
  group5: "static/img/group-91@2x.png",
  x1045Mins: "10:45 mins",
  group6: "static/img/group-89@2x.png",
  number3: "4",
  group7: "static/img/group-101@2x.png",
  pdfFiles: "PDF Files",
  number4: "5",
  quiz: "Quiz",
  group8: "static/img/group-103@2x.png",
  address2: "2 questions",
  group9: "static/img/group-102@2x.png",
  chapter2: "Chapter 2",
  address3: "3 videos",
  chapter3: "Chapter 3",
  address4: "3 videos",
  frame1522Props: frame15222Data,
  baseCheckbox1Props: baseCheckbox3Data,
  baseCheckbox2Props: baseCheckbox4Data,
  baseCheckbox3Props: baseCheckbox5Data,
  group31Props: group311Data,
};

const pdf11Data = {
  pdfFile1RelatedToVideo1: "PDF File 1 Related to Video 1",
  downloadBtn: "static/img/download-btn@2x.png",
};

const pdf12Data = {
  pdfFile1RelatedToVideo1: "PDF File 2 Related to Video 1",
  downloadBtn: "static/img/download-btn-1@2x.png",
  className: "pdf-2",
};

const bTNComponent27Data = {
  className: "read-more-btn-7",
};

const unlockPowerOfStudySection24Data = {
  group147: "static/img/group-147-5@2x.png",
  bTNComponent2Props: bTNComponent27Data,
};

const introductionVideoMyLearningMoreSectData = {
  logo1: "static/img/logo-11@2x.png",
  chemistry: "Chemistry",
  math: "Math",
  physics: "Physics",
  biology: "Biology",
  language: "English",
  place: "More",
  faq: "FAQ",
  lines: "static/img/lines@1x.png",
  descriptionAboutTh:
    "Description about the lesson or video goes here, Lorem ipsum dolor sit amet consectetur. Posuere non est vitae ultrices nulla in vitae est faucibus. Platea dignissim purus ut ornare lacinia.",
  pdfFile3RelatedToVideo1: "PDF File 3 Related to Video 1",
  downloadBtn: "static/img/download-btn-2@2x.png",
  logo2: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons-2@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  smallVideosDropdownForMyLearningClaProps:
    smallVideosDropdownForMyLearningClaData,
  pdf11Props: pdf11Data,
  pdf12Props: pdf12Data,
  unlockPowerOfStudySection2Props: unlockPowerOfStudySection24Data,
};

const group721Data = {
  ellipse23: "static/img/ellipse-23@2x.png",
  address: "10 December 2022",
};

const question11Data = {
  group72Props: group721Data,
};

const group722Data = {
  ellipse23: "static/img/ellipse-23-1@2x.png",
  address: "05 December 2022",
};

const question12Data = {
  group72Props: group722Data,
};

const group723Data = {
  ellipse23: "static/img/ellipse-23-2@2x.png",
  address: "02 December 2022",
};

const group724Data = {
  ellipse23: "static/img/ellipse-23-3@2x.png",
  address: "28 November 2022",
};

const question13Data = {
  className: "question-2",
  group72Props: group724Data,
};

const bTNComponent4Data = {
  children: "Have a Question? We are here to help you",
};

const frame15225Data = {
  unit1: "Unit 1",
  address: "3 Chapters",
};

const baseCheckbox8Data = {
  className: "base-checkbox-6",
};

const baseCheckbox9Data = {
  className: "base-checkbox-7",
};

const baseCheckbox10Data = {
  className: "base-checkbox-8",
};

const frame15226Data = {
  unit1: "Unit 2",
  address: "3 Chapters",
  className: "frame-15",
};

const frame15227Data = {
  unit1: "Unit 3",
  address: "4 Chapters",
  className: "frame-15",
};

const group312Data = {
  frame15221Props: frame15226Data,
  frame15222Props: frame15227Data,
};

const smallVideosDropdownForUnpaidClassData = {
  chapter1: "Chapter 1",
  address1: "3 videos",
  number1: "2",
  frame3231: "static/img/frame-323@2x.png",
  group1: "static/img/group-90@2x.png",
  videoLesson2SecondLineTitle: (
    <React.Fragment>
      Video Lesson 2<br />
      Second line title
    </React.Fragment>
  ),
  group2: "static/img/group-91@2x.png",
  x0600Mins: "06:00 mins",
  group3: "static/img/group-108@2x.png",
  number2: "3",
  frame3232: "static/img/frame-323@2x.png",
  group4: "static/img/group-90@2x.png",
  videoLesson3: "Video Lesson 3",
  group5: "static/img/group-91@2x.png",
  x1045Mins: "10:45 mins",
  group6: "static/img/group-108@2x.png",
  number3: "4",
  group7: "static/img/group-101@2x.png",
  pdfFiles: "PDF Files",
  group8: "static/img/group-117@2x.png",
  number4: "5",
  quiz: "Quiz",
  group9: "static/img/group-103@2x.png",
  address2: "2 questions",
  group10: "static/img/group-102@2x.png",
  group11: "static/img/group-117@2x.png",
  chapter2: "Chapter 2",
  address3: "3 videos",
  chapter3: "Chapter 3",
  address4: "3 videos",
  frame1522Props: frame15225Data,
  baseCheckbox1Props: baseCheckbox8Data,
  baseCheckbox2Props: baseCheckbox9Data,
  baseCheckbox3Props: baseCheckbox10Data,
  group31Props: group312Data,
};

const bTNComponent28Data = {
  className: "read-more-btn-8",
};

const unlockPowerOfStudySection25Data = {
  group147: "static/img/group-147-5@2x.png",
  bTNComponent2Props: bTNComponent28Data,
};

const footerAndUnlockThePowerSectionData = {
  group148: "static/img/letter-1-5@2x.svg",
  socialMediaIcons: "static/img/social-media-icons-2@2x.png",
  group147: "static/img/scroll-top-btn-5@2x.svg",
  bTNComponent2Props: unlockPowerOfStudySection25Data,
};

const baseCheckbox11Data = {
  className: "base-checkbox-2",
};

const frame2991Data = {
  grade6: "Grade 6",
  baseCheckboxProps: baseCheckbox11Data,
};

const baseCheckbox12Data = {
  className: "base-checkbox-2",
};

const frame3001Data = {
  math: "Math",
  baseCheckboxProps: baseCheckbox12Data,
};

const baseCheckbox13Data = {
  className: "base-checkbox-2",
};

const frame3002Data = {
  math: "Physics",
  baseCheckboxProps: baseCheckbox13Data,
};

const baseCheckbox14Data = {
  className: "base-checkbox-2",
};

const frame3003Data = {
  math: "Chemistry",
  baseCheckboxProps: baseCheckbox14Data,
};

const baseCheckbox15Data = {
  className: "base-checkbox-2",
};

const frame3004Data = {
  math: "Biology",
  baseCheckboxProps: baseCheckbox15Data,
};

const baseCheckbox16Data = {
  className: "base-checkbox-2",
};

const frame3005Data = {
  math: "English",
  baseCheckboxProps: baseCheckbox16Data,
};

const baseCheckbox17Data = {
  className: "base-checkbox-2",
};

const frame2992Data = {
  grade6: "Grade 7",
  className: "frame-299",
  baseCheckboxProps: baseCheckbox17Data,
};

const group521Data = {
  frame299Props: frame2992Data,
};

const baseCheckbox18Data = {
  className: "base-checkbox-2",
};

const frame2993Data = {
  grade6: "Grade 8",
  className: "frame-299",
  baseCheckboxProps: baseCheckbox18Data,
};

const group522Data = {
  frame299Props: frame2993Data,
};

const baseCheckbox19Data = {
  className: "base-checkbox-2",
};

const frame2994Data = {
  grade6: "Grade 9",
  className: "frame-299",
  baseCheckboxProps: baseCheckbox19Data,
};

const group523Data = {
  frame299Props: frame2994Data,
};

const baseCheckbox20Data = {
  className: "base-checkbox-2",
};

const frame2995Data = {
  grade6: "Grade 10",
  className: "frame-299",
  baseCheckboxProps: baseCheckbox20Data,
};

const group524Data = {
  frame299Props: frame2995Data,
};

const buyGradesDropdownData = {
  frame299Props: frame2991Data,
  frame3001Props: frame3001Data,
  frame3002Props: frame3002Data,
  frame3003Props: frame3003Data,
  frame3004Props: frame3004Data,
  frame3005Props: frame3005Data,
  group521Props: group521Data,
  group522Props: group522Data,
  group523Props: group523Data,
  group524Props: group524Data,
};

const cancelBTN2Data = {
  className: "frame-129",
};

const buyCoursePopupData = {
  buyGradesDropdownProps: buyGradesDropdownData,
  cancelBTNProps: cancelBTN2Data,
};

const unlockPowerOfStudySection5Data = {
  group148: "static/img/group-148-1@2x.png",
  group147: "static/img/group-147@2x.png",
};

const number3Data = {
  children: "1",
};

const questionAndNumber5Data = {
  numberProps: number3Data,
};

const answer123Data = {
  baseCheckboxProps: baseCheckbox10Data,
};

const answer39Data = {
  answer3: "Answer 2",
  baseCheckboxProps: baseCheckbox11Data,
};

const answer310Data = {
  answer3: "Answer 3",
  baseCheckboxProps: baseCheckbox12Data,
};

const answer311Data = {
  answer3: "Answer 4",
  baseCheckboxProps: baseCheckbox13Data,
};

const number4Data = {
  children: "2",
  className: "number-1",
};

const questionAndNumber22Data = {
  numberProps: number4Data,
};

const answer124Data = {
  baseCheckboxProps: baseCheckbox14Data,
};

const answer312Data = {
  answer3: "Answer 2",
  baseCheckboxProps: baseCheckbox15Data,
};

const answer313Data = {
  answer3: "Answer 3",
  baseCheckboxProps: baseCheckbox16Data,
};

const answer314Data = {
  answer3: "Answer 4",
  baseCheckboxProps: baseCheckbox17Data,
};

const number24Data = {
  children: "3",
};

const questionAndNumber32Data = {
  number2Props: number24Data,
};

const answer125Data = {
  baseCheckboxProps: baseCheckbox18Data,
};

const answer315Data = {
  answer3: "Answer 2",
  baseCheckboxProps: baseCheckbox19Data,
};

const answer316Data = {
  answer3: "Answer 3",
  baseCheckboxProps: baseCheckbox20Data,
};

const answer317Data = {
  answer3: "Answer 4",
  baseCheckboxProps: baseCheckbox10Data,
};

const number25Data = {
  children: "4",
  className: "number-5",
};

const questionAndNumber42Data = {
  number2Props: number25Data,
};

const answer126Data = {
  baseCheckboxProps: baseCheckbox10Data,
};

const answer318Data = {
  answer3: "Answer 2",
  baseCheckboxProps: baseCheckbox10Data,
};

const answer319Data = {
  answer3: "Answer 3",
  baseCheckboxProps: baseCheckbox10Data,
};

const answer320Data = {
  answer3: "Answer 4",
  baseCheckboxProps: baseCheckbox10Data,
};

const quizesData = {
  logo1: "static/img/logo@2x.png",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet consectetur. Id eget libero adipiscing tempus tellus iaculis at. Vitae elit vulputate viverra leo a. Eu at sed mattis adipiscing tincidunt magnis vitae felis rhoncus. Magna vel enim eget risus. Nullam sem donec vestibulum elementum mi. Eu viverra dictum a ullamcorper nunc.",
  contactUs: "CONTACT US",
  address: "71 299 280  -  71 182698",
  schooltubeOnlineHotmailCom: "schooltube.online@hotmail.com",
  followUs: "FOLLOW US",
  socialMediaIcons: "static/img/social-media-icons@2x.png",
  copyright2022Schoo: "COPYRIGHT 2022 SCHOOLTUBE. ALL RIGHTS RESERVED.",
  poweredByBrainkets: "POWERED BY BRAINKETS",
  quizes: "Quizes",
  logo2: "static/img/logo@2x.png",
  unlockPowerOfStudySectionProps: unlockPowerOfStudySection5Data,
  questionAndNumberProps: questionAndNumber5Data,
  answer121Props: answer123Data,
  answer31Props: answer39Data,
  answer32Props: answer310Data,
  answer33Props: answer311Data,
  questionAndNumber2Props: questionAndNumber22Data,
  answer122Props: answer124Data,
  answer34Props: answer312Data,
  answer35Props: answer313Data,
  answer36Props: answer314Data,
  questionAndNumber3Props: questionAndNumber32Data,
  answer123Props: answer125Data,
  answer37Props: answer315Data,
  answer38Props: answer316Data,
  answer39Props: answer317Data,
  questionAndNumber4Props: questionAndNumber42Data,
  answer124Props: answer126Data,
  answer310Props: answer318Data,
  answer311Props: answer319Data,
  answer312Props: answer320Data,
};
