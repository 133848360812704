import React, { useState } from "react";
import {
  Button,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  DialogActions,
  Box,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close icon
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";
import GradeDropdownPanel from "./GradeDropdownPanel"; // Import the GradeDropdownPanel component
import TextTR from "../HomePage/TextTR";
import { URL } from "../../static/data/config";

function extractTextFromComponent(Component, props) {
  const element = <Component {...props} />;
  return element.props.text || "";
}
const namePlaceholder = extractTextFromComponent(TextTR, { text: "Name" });
const phoneNumberPlaceholder = extractTextFromComponent(TextTR, {
  text: "Phone Number",
});
const regionPlaceholder = extractTextFromComponent(TextTR, { text: "Region" });
const cityPlaceholder = extractTextFromComponent(TextTR, { text: "City" });
const streetPlaceholder = extractTextFromComponent(TextTR, { text: "Street" });
const buildinglaceholder = extractTextFromComponent(TextTR, {
  text: "Building",
});
const noteslaceholder = extractTextFromComponent(TextTR, { text: "Notes" });

const DeliveryPaymentModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    region: "",
    city: "",
    street: "",
    building: "",
    notes: "",
  });

  const [selectedGrades, setSelectedGrades] = useState([]);
  const [totalPriceFromPanel, setTotalPriceFromPanel] = useState(0);

  const offers = JSON.parse(localStorage.getItem("offers"));
  const grades = JSON.parse(localStorage.getItem("grades"));

  const { getTranslatedWord } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleGradesChange = (gradesData) => {
    setSelectedGrades(gradesData);
  };

  const handleTotalPriceChange = (newPrice) => {
    setTotalPriceFromPanel(newPrice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("phoneNumber", formData.phoneNumber);
      form.append("region", formData.region);
      form.append("city", formData.city);
      form.append("street", formData.street);
      form.append("building", formData.building);
      form.append("notes", formData.notes);
      form.append("grades", JSON.stringify(selectedGrades));
      form.append(
        "token",
        localStorage.getItem("login-token") ??
          sessionStorage.getItem("login-token")
      );

      const response = await axios.post(
        `${URL}material/make-delivery-request`,
        form
      );
      if (response.data.succeeded) {
        Swal.fire({
          icon: "success",
          title: getTranslatedWord(response.data.title),
          text: getTranslatedWord(response.data.message),
        });
      } else {
        Swal.fire({
          icon: "error",
          title: getTranslatedWord(response.data.title),
          text: getTranslatedWord(response.data.message),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: getTranslatedWord("Error"),
        text: getTranslatedWord(error.message),
      });
    }
    handleClose();
  };

  const regions = [
    { label: "جبيل", value: "جبيل" },
    { label: "كسروان", value: "كسروان" },
    { label: "المتن", value: "المتن" },
    { label: "بيروت", value: "بيروت" },
    { label: "بعبدا", value: "بعبدا" },
    { label: "بنت جبيل", value: "بنت جبيل" },
    { label: "عاليه", value: "عاليه" },
    { label: "الشوف", value: "الشوف" },
    { label: "الهرمل", value: "الهرمل" },
    { label: "بعلبك", value: "بعلبك" },
    { label: "زحلة", value: "زحلة" },
    { label: "البقاع الغربي", value: "البقاع الغربي" },
    { label: "راشيا", value: "راشيا" },
    { label: "عكار", value: "عكار" },
    { label: "طرابلس", value: "طرابلس" },
    { label: "زغرتا", value: "زغرتا" },
    { label: "بشري", value: "بشري" },
    { label: "البترون", value: "البترون" },
    { label: "الكورة", value: "الكورة" },
    { label: "المنيه - الضنية", value: "المنيه - الضنية" },
    { label: "صيدا", value: "صيدا" },
    { label: "صور", value: "صور" },
    { label: "جزين", value: "جزين" },
    { label: "النبطية", value: "النبطية" },
    { label: "حاصبيا", value: "حاصبيا" },
    { label: "مرجعيون", value: "مرجعيون" },
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
  
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center", // Center the title
          alignItems: "center",
          position: "relative", // Allow positioning of the close button
        }}
      >
        Delivery Form
        <span
          style={{
            position: "absolute",
            right: 20,
            color: "black",
            zIndex: 100,
          }}
        >
          <Button
            onClick={handleClose}
            color="error"
            sx={{
              minWidth: "auto",
              backgroundColor: "transparent",
              boxShadow: "none",
            }} // Remove background color
          >
            <CloseIcon />
          </Button>
        </span>
      </DialogTitle>
      <Box dir="rtl" sx={{ p: 2, textAlign: "center", fontWeight: "bold", color: "#304FFE" }}>
    املأ الآتي لنوصل بطاقة "سكول تيوب" إلى منزلك:
  </Box>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <TextField
            label="الاسم"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={namePlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="رقم الهاتف"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            type="number"
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={phoneNumberPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            id="filled-select-currency"
            select
            label="القضاء"
            name="region"
            value={formData.region}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={regionPlaceholder}
            required
          >
            {regions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="المدينة أو القرية"
            name="city"
            value={formData.city}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={cityPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="الشارع"
            name="street"
            value={formData.street}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={streetPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RemoveRoadIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="المبنى"
            name="building"
            value={formData.building}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={buildinglaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="ملاحظات"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            sx={{
              m: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "22px", // Add border radius
                "& fieldset": {
                  borderColor: "#304FFE", // Set the default border color
                },
                "&:hover fieldset": {
                  borderColor: "#304FFE", // Set the border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#304FFE", // Set the border color when focused
                },
              },
            }}
            placeholder={noteslaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SpeakerNotesIcon />
                </InputAdornment>
              ),
            }}
          />
          <GradeDropdownPanel
            grades={grades}
            offers={offers}
            onGradesChange={handleGradesChange}
            onTotalPriceChange={handleTotalPriceChange}
          />
        </DialogContent>
        <div dir="rtl" className="px-2">
          <p className="font-bold mb-2 mr-6">يضاف على السعر 3$ ثمن التوصيل</p>
        </div>
        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            p: 3,
            z: 100,
          }}
        >
          <Box display="flex" width="100%" sx={{ zIndex: 100 }}>
            <Box
              sx={{
                flex: 1,
                justifyContent: "flex-start",
                textAlign: "left", // Align text to the left
                zIndex: 100, // Add z-index
              }}
            >
              <p className="font-bold mb-2">
                Price: ${totalPriceFromPanel.toFixed(2)}
              </p>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
                zIndex: 100, // Add z-index
              }}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeliveryPaymentModal;
