import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import TextTR from "../HomePage/TextTR";
import HomeOfferCard from "./HomeOfferCard";

const HomeOffers = ({ offers }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <div className="mt-4 sm:mt-10 !w-full md:!w-[70%] !flex-col !items-start !justify-start">
      <div className="pl-4 lg:mb-4 ">
        <h1 className="!text-black !font-bold !text-[20px] md:!text-4xl flex items-start justify-start">
          <TextTR text="Annual subscription offers" />
        </h1>
      </div>
      <div className="mt-[0.5rem] flex items-start justify-start gap-3 md:flex-wrap px-3">
        {isMobile ? (
          <Swiper spaceBetween={3} slidesPerView={4}>
            {offers.map((offer, index) => (
              <SwiperSlide key={index}>
                <HomeOfferCard image={offer.image} title={offer.title} label={offer.label}  label2={offer.second_label} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          offers.map((offer, index) => (
            <HomeOfferCard
              key={index}
              image={offer.image}
              title={offer.title}
              label={offer.label} 
              label2={offer.second_label} 
            />
          ))
        )}
      </div>
    </div>
  );
};

export default HomeOffers;
