import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./SmallVideosDropdownForMyLearningCla.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import axios from "axios";
import Swal from "sweetalert2";
import FaqElement from "../IntroductionVideoMyLearningMoreSect/FaqElement";
import MaterialItem from "./MaterialItem";
import AttachmentDialog from "./AttachmentDialog";

function SmallVideosDropdownForMyLearningCla(props) {
  const history = useHistory();

  const [finishedIds, setFinishedIds] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);

  function getFinishedIds() {
    if (
      props.drop_map &&
      props.drop_map.materials &&
      props.drop_map.materials[props.material_id] &&
      props.drop_map.materials[props.material_id].units &&
      props.drop_map.materials[props.material_id].units.length > 0
    ) {
      props.drop_map.materials[props.material_id].units.forEach((unit) => {
        if (unit.videos && unit.videos.length > 0) {
          const videos = unit.videos.filter(
            (video) => video.is_finished == true
          );
          videos.forEach((video) => finishedIds.push(video.id));
        }
      });
    }

    if (
      props.drop_map &&
      props.drop_map.materials &&
      props.drop_map.materials[props.material_id] &&
      props.drop_map.materials[props.material_id].units &&
      props.drop_map.materials[props.material_id].units.length > 0
    ) {
      props.drop_map.materials[props.material_id].units.forEach((unit) => {
        if (unit.chapters && unit.chapters.length > 0) {
          unit.chapters.forEach((chapter) => {
            if (chapter.videos && chapter.videos.length > 0) {
              const videos = chapter.videos.filter(
                (video) => video.is_finished == true
              );
              videos.forEach((video) => finishedIds.push(video.id));
            }
          });
        }
      });
    }

    // return Ids;
  }
  useEffect(() => {
    getFinishedIds();
  }, []);

  useEffect(() => {
    if (props.isVideoEnded) {
      const newId = props.videoEndedId;
      const updatedIds = [...finishedIds, newId];
      setFinishedIds(updatedIds);
    }
  }, [props.isVideoEnded, props.videoEndedId, finishedIds]);
  // chapters bug in phynal tab, general sciences
  // make the url in one place

  const [videoCheckedData, setVideoCheckedData] = useState([]);

  useEffect(() => {
    if (props.isVideoEnded) {
      handleVideoChecked({ id: props.videoProgress.id });
    }
  }, [props.isVideoEnded]);

  async function handleVideoChecked({ id }) {
    if (id || props.videoProgress.id) {
      let formData = new FormData();
      formData.append("token", localStorage.getItem("login-token"));
      formData.append("video_id", id || props.videoProgress.id);
      formData.append(
        "is_finished",
        props.videoProgress.progress > 0.6 ? true : false
      );
      formData.append("time", props.videoProgress.progress);

      try {
        await axios
          .post(`${props.url}material/set-video-completed`, formData)
          .then((res) => {
            console.log(res.data);
            setVideoCheckedData(res);
          });
      } catch (err) {
        alert(err.message);
      }
    }
  }

  // useEffect(() => {
  //   if (videoEnded) {
  //     handleVideoChecked();
  //   }
  // }, [videoEnded, handleVideoChecked]);

  const { isLoggedIn } = useSelector((state) => state.checkLogin);

  const isAuthorized = ({ item, access }) => {
    if (!isLoggedIn) {
      handleAlert("You are not logged in!");
      return false;
    }

    if (item.itemType === "Video") {
      if (!access || (item.is_free === 0 && !access)) {
        handleAlert("Please Buy this Material!");
        return false;
      }
    } else if (item.itemType === "Chapter" || item.itemType === "Quiz") {
      if (!access) {
        handleAlert("Please Buy this Material!");
        return false;
      }
    }

    return true;
  };

  function handleAlert(alert) {
    Swal.fire({
      icon: "warning",
      title: "No Access",
      text: alert,
    });
  }

  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [selectedMaterialTab, setSelectedMaterialTab] = useState("videos");
  const [itemsToDisplay, setItemsToDisplay] = useState({ id: "" });

  useEffect(() => {
    const selected = props.drop_map?.materials.find(
      (material) => material.id === props.material_id
    );
    setSelectedMaterial(selected || []);
  }, [props.material_id]);

  useEffect(() => {
    if (selectedMaterial.units && selectedMaterial.units.length > 0) {
      const firstUnit = selectedMaterial.units[0];
      if (firstUnit.tabs && firstUnit.tabs.length > 0) {
        setItemsToDisplay({
          id: firstUnit.tabs[0].id,
        });
      }
    }
  }, [selectedMaterial]);

  const tabChangeHandler = (type) => {
    setSelectedMaterialTab(type);
  };

  const [checkedVideos, setCheckedVideos] = useState([]);
  const [expandedTab, setExpandedTab] = useState([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachments, setAttachments] = useState(null);

  const attachmentHandler = () => {
    setOpenAttachment((open) => !open);
  };

  const checkWatchedVideos = () => {
    const { progress, url } = props.videoProgress;

    if (progress >= 0.6) {
      const material = props.drop_map.materials.find(
        (material) => material.id === props.material_id
      );

      if (material) {
        material.units.forEach((unit) => {
          unit.tabs.forEach((tab) => {
            tab.items.forEach((item) => {
              if (item.itemType !== "Chapter") {
                if (item.url === url && !finishedIds.includes(item.id)) {
                  setCheckedVideos((prevIds) => [...prevIds, item.id]);
                }
              } else if (item.itemType === "Chapter") {
                item.tabs.forEach((subTab) => {
                  subTab.items.forEach((subItem) => {
                    if (
                      subItem.url === url &&
                      !finishedIds.includes(subItem.id)
                    ) {
                      setCheckedVideos((prevIds) => [...prevIds, subItem.id]);
                    }
                  });
                });
              }
            });
          });
        });
      }
    }
  };
  useEffect(() => {
    checkWatchedVideos();
  }, [props.videoProgress]);
  const handleItemClick = (item) => {
    setSelectedItemId(item.id);
  };
  async function handleEbookClick(item, mAccess, subItem) {
    const authItem = subItem
      ? { item: subItem, access: mAccess }
      : { item: item, access: mAccess };

    if (!isAuthorized(authItem)) {
      return;
    }

    const token = localStorage.getItem("login-token");

    if (item.itemType !== "Chapter") {
      if (item.id) {
        const url = `${props.url}material/ebook?id=${item.id}&token=${token}`;

        const newWindow = window.open(url, "_blank");

        newWindow.addEventListener("load", () => {
          newWindow.history?.replaceState({}, "", url.split("?")[0]);
        });
      } else {
        handleAlert("Undefined ebook id");
      }
    } else {
      if (subItem) {
        const url = `${props.url}material/ebook?id=${subItem.id}&token=${token}`;

        const newWindow = window.open(url, "_blank");

        newWindow.addEventListener("load", () => {
          newWindow.history?.replaceState({}, "", url.split("?")[0]);
        });
      } else {
        handleAlert("Undefined ebook id");
      }
    }
  }

  const handleVideoClick = (item, mAccess, subItem) => {
    const authItem = subItem
      ? { item: subItem, access: mAccess }
      : { item: item, access: mAccess };
    if (item.is_free == 0) {
      if (!isAuthorized(authItem)) {
        return;
      }
    }

    if (item.itemType !== "Chapter") {
      handleVideoChecked({ id: item.id });
      props.handleVideoChange({
        id: item.id,
        url: item.url,
        name: item.video_name,
      });
    } else {
      if (subItem) {
        handleVideoChecked({ id: subItem.id });
        props.handleVideoChange({
          id: subItem.id,
          url: subItem.url,
          name: subItem.video_name,
        });
      } else {
        handleAlert("Undefined video id");
      }
    }
  };

  const handleQuizClick = (item, mAccess, subItem) => {
    const authItem = subItem
      ? { item: subItem, access: mAccess }
      : { item: item, access: mAccess };

    if (!isAuthorized(authItem)) {
      return;
    }

    if (item.itemType !== "Chapter") {
      history.push(`/quizes/${item.id}`);
    } else {
      if (subItem) {
        history.push(`/quizes/${subItem.id}`);
      } else {
        handleAlert("Undefined quiz id");
      }
    }
  };

  const itemTabChangeHandler = (id) => {
    setItemsToDisplay({ id });
  };

  const handleAccordionToggle = (event, isExpanded, unit, unitIndex) => {
    if (isExpanded) {
      setExpandedTab(`${unit.title}-${unitIndex}`);
    } else {
      setExpandedTab(false);
    }

    if (unit.tabs && unit.tabs.length > 0) {
      setItemsToDisplay({
        id: unit.tabs[0].id,
      });
    }
  };

  function countItems(tabs) {
    let videoCount = 0;
    let quizCount = 0;
    let ebookCount = 0;
    let chapterCount = 0;

    tabs.forEach((tab) => {
      tab.items.forEach((item) => {
        if (item.itemType === "Video") {
          videoCount += 1;
        } else if (item.itemType === "Quiz") {
          quizCount += 1;
        } else if (item.itemType === "Ebook") {
          ebookCount += 1;
        } else if (item.itemType === "Chapter") {
          chapterCount += 1;
          const chapterCounts = countItems(item.tabs);
          videoCount += chapterCounts.videoCount;
          quizCount += chapterCounts.quizCount;
          ebookCount += chapterCounts.ebookCount;
          chapterCount += chapterCounts.chapterCount;
        }
      });
    });

    return { videoCount, quizCount, ebookCount, chapterCount };
  }

  function getCounts(material) {
    const units = material?.units;
    const result = units?.map((unit) => {
      const { videoCount, quizCount, ebookCount, chapterCount } = countItems(
        unit.tabs
      );

      return {
        unitTitle: unit.title,
        videoCount,
        quizCount,
        ebookCount,
        chapterCount,
      };
    });

    return result;
  }

  const handleAttachmentClick = (e, item) => {
    e.stopPropagation();
    if (item.files) {
      setAttachments(item.files);
      setOpenAttachment(true);
    }
  };

  return (
    <>
      <AttachmentDialog
        handleClose={attachmentHandler}
        open={openAttachment}
        attachments={attachments}
      />
      <div className="category-tabs-container">
        <div className="w-full h-20 flex items-start justify-start">
          {selectedMaterial.tab_1_title?.length > 0 && (
            <button
              className={`w-1/2 h-full p-2 font-bold
                        ${
                          selectedMaterialTab === "videos"
                            ? "bg-blue-700 text-white"
                            : " bg-blue-200 text-blue-700 "
                        }`}
              onClick={() => tabChangeHandler("videos")}
            >
              {selectedMaterial.tab_1_title}
            </button>
          )}
          {selectedMaterial.tab_2_title?.length > 0 && (
            <button
              className={`w-1/2 h-full p-2 font-bold
                          ${
                            selectedMaterialTab === "faq"
                              ? "bg-blue-700 text-white"
                              : " bg-blue-200 text-blue-700"
                          }`}
              onClick={() => tabChangeHandler("faq")}
            >
              {selectedMaterial.tab_2_title}
            </button>
          )}
        </div>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="tab-box"
          >
            {selectedMaterialTab === "videos" &&
              selectedMaterial?.units?.map((unit, unitIndex) => (
                <Accordion
                  key={unitIndex}
                  sx={{ backgroundColor: "#eefdff" }}
                  onChange={(event, isExpanded) =>
                    handleAccordionToggle(event, isExpanded, unit, unitIndex)
                  }
                  expanded={expandedTab === `${unit.title}-${unitIndex}`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="font-bold"
                  >
                    <div>
                      <p className="!text-[#00567c]">{unit.title}</p>
                      <div className="flex items-start justify-start gap-2 capitalize text-sm text-[#c4c4c4]">
                        {getCounts(selectedMaterial)[unitIndex]?.videoCount >
                          0 && (
                          <p>
                            videos:{" "}
                            {getCounts(selectedMaterial)[unitIndex]?.videoCount}
                          </p>
                        )}
                        {getCounts(selectedMaterial)[unitIndex]?.quizCount >
                          0 && (
                          <p>
                            qizzes:{" "}
                            {getCounts(selectedMaterial)[unitIndex]?.quizCount}
                          </p>
                        )}
                        {getCounts(selectedMaterial)[unitIndex]?.ebookCount >
                          0 && (
                          <p>
                            ebooks:{" "}
                            {getCounts(selectedMaterial)[unitIndex]?.ebookCount}
                          </p>
                        )}
                        {getCounts(selectedMaterial)[unitIndex]?.chapterCount >
                          0 && (
                          <p>
                            chapters:{" "}
                            {
                              getCounts(selectedMaterial)[unitIndex]
                                ?.chapterCount
                            }
                          </p>
                        )}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className="!mb-2 flex items-start gap-2">
                        {unit?.tabs?.map((tab, tabIndex) => (
                          <button
                            key={tabIndex}
                            className={`w-auto p-2 font-bold rounded ${
                              itemsToDisplay.id === tab.id
                                ? "bg-blue-700 text-white"
                                : "bg-blue-200 text-blue-700"
                            }`}
                            onClick={() => itemTabChangeHandler(tab.id)}
                          >
                            {tab.title}
                          </button>
                        ))}
                      </div>
                      <div className="!mt-2">
                        {unit?.tabs
                          ?.find((tabUnit) => tabUnit.id === itemsToDisplay.id)
                          ?.items?.map((item, itemIndex) => (
                            <div key={itemIndex} className="gap-2">
                              <MaterialItem
                                item={item}
                                checkedVideos={checkedVideos || []}
                                isChecked={
                                  (checkedVideos &&
                                    checkedVideos?.includes(item.id)) ||
                                  // videoCheckedData?.includes(item.id) ||
                                  item.is_finished
                                }
                                isSelected={selectedItemId === item.id} // Pass selected state
                                handleEbookClick={(subItem) => {
                                  handleItemClick(item);
                                  handleEbookClick(
                                    item,
                                    selectedMaterial.material_access,
                                    subItem
                                  );
                                }}
                                handleVideoClick={(subItem) => {
                                  handleItemClick(item);
                                  handleVideoClick(
                                    item,
                                    selectedMaterial.material_access,
                                    subItem
                                  );
                                }}
                                handleQuizClick={(subItem) => {
                                  handleItemClick(item);
                                  handleQuizClick(
                                    item,
                                    selectedMaterial.material_access,
                                    subItem
                                  );
                                }}
                                handleAttachmentClick={(e) => {
                                  handleItemClick(item);
                                  handleAttachmentClick(e, item);
                                }}
                              />
                            </div>
                          )) || <h1>No Items Found</h1>}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            {selectedMaterialTab === "faq" && <FaqElement />}
          </Box>
        </Box>
      </div>
    </>
  );
}

export default SmallVideosDropdownForMyLearningCla;
