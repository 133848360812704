import "./Highlights.css";
import { useState, useRef, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import TextTR from "../HomePage/TextTR";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Highlights = (props) => {
  const [currentStory, setCurrentStory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile threshold (768px)
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const storiesHandler = (highlight) => {
    const mediaItems = [];
    const childHighlights = props.hightlights?.filter(
      (h) => h.parent === highlight.id
    );

    if (highlight.video) {
      mediaItems.push({ url: highlight.video, type: "video" });
    }

    if (childHighlights?.length > 0) {
      childHighlights.forEach((child) => {
        if (child.main_image) {
          mediaItems.push({
            url: child.main_image,
            type: "image",
            duration: 10,
          });
        }
        if (child.video) {
          mediaItems.push({ url: child.video, type: "video" });
        }
      });
    }

    setCurrentStory(mediaItems);
    setCurrentIndex(0);
  };

  const handleCloseStory = () => {
    setCurrentStory(null);
    document.body.style.overflowY = "auto";
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const progressValue =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progressValue);
    }
  };

  const handleVideoEnd = () => {
    if (currentStory && currentIndex < currentStory.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setProgress(0);
    } else {
      handleCloseStory();
    }
  };

  useEffect(() => {
    setProgress(0);
    if (currentStory && currentStory[currentIndex]?.type === "video") {
      videoRef.current.play();
    } else if (currentStory) {
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            if (currentIndex < currentStory.length - 1) {
              setCurrentIndex((prevIndex) => prevIndex + 1);
            } else {
              handleCloseStory();
            }
            return 0;
          }
          return prev + 10;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [currentIndex, currentStory]);

  return (
    <>
     <div className="mt-[0.5rem] lg:mt-[4rem]  w-full md:w-[70%]">
        <div className="pl-4 ">
          <h1 className="text-black font-bold text-[20px] md:text-4xl">
            <TextTR text="msg_featured_courses" />
          </h1>
        </div>
        <div className="mt-4">
          {isMobile ? (
            <Swiper
              spaceBetween={5}
              slidesPerView={4}
              modules={[Pagination]} // You can still include this module if needed elsewhere
              pagination={false} // Disable pagination (dots)
            >
              {props.hightlights?.map(
                (h, i) =>
                  h.parent === null && (
                    <SwiperSlide key={i}>
                      <div className="flex flex-col items-center">
                        <div
                          className="shadow-xl cursor-pointer rounded-full h-[80px] w-[80px]"
                          style={{
                            backgroundImage: `url(${
                              h.main_image ||
                              h.thumbnail ||
                              `../schooltube-logo.png`
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            border: "2px solid red",
                          }}
                          onClick={() => storiesHandler(h)}
                        ></div>
                        <p className="my-2 text-black font-semibold text-sm text-center">
                          {h.title}
                        </p>
                      </div>
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          ) : (
            <div className="flex">
              {props.hightlights?.map(
                (h, i) =>
                  h.parent === null && (
                    <div key={i} className="flex flex-col items-center">
                      <div
                        className="shadow-xl cursor-pointer ml-6 rounded-full h-[100px] w-[100px]"
                        style={{
                          backgroundImage: `url(${
                            h.main_image ||
                            h.thumbnail ||
                            `../schooltube-logo.png`
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          border: "2px solid red",
                        }}
                        onClick={() => storiesHandler(h)}
                      ></div>
                      <p className="ml-6 my-2 text-black font-semibold">
                        {h.title}
                      </p>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      </div>

      {currentStory && (
        <div className="fixed top-0 left-0 w-full h-full bg-black z-[200] flex items-center justify-center">
          {/* Custom Progress Bar */}
          <div className="absolute top-0 left-0 w-full flex gap-1 p-2">
            {currentStory.map((_, idx) => (
              <div
                key={idx}
                className="h-1 bg-gray-500 rounded"
                style={{
                  flex: 1,
                  background:
                    idx < currentIndex
                      ? "#fff"
                      : idx === currentIndex
                      ? `linear-gradient(to right, #fff ${progress}%, #444 ${progress}%)`
                      : "#444",
                }}
              ></div>
            ))}
          </div>

          {/* Close Button */}
          <div
            className="absolute top-4 right-6 text-white cursor-pointer"
            onClick={handleCloseStory}
          >
            <CancelIcon />
          </div>

          {/* Story Player */}
          <div className="w-full h-[90vh] flex items-center justify-center">
            {currentStory[currentIndex]?.type === "video" ? (
              <video
                ref={videoRef}
                src={currentStory[currentIndex].url}
                onTimeUpdate={updateProgress}
                onEnded={handleVideoEnd}
                autoPlay
                playsInline
                className="w-full h-full"
              />
            ) : (
              <img
                src={currentStory[currentIndex].url}
                alt="Story"
                className="w-full h-full object-contain"
                onLoad={() => setProgress(100)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Highlights;
