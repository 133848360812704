import { useState } from "react";
import { Button, DialogTitle, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Tr from "../translation/Utils";
import GradeDropdownPanel from "./GradeDropdownPanel";

const OMTPaymentModal = ({ open, handleClose }) => {
  const [price, setPrice] = useState(0);
  const [totalPriceFromPanel, setTotalPriceFromPanel] = useState(0);
  const offers = JSON.parse(localStorage.getItem("offers"));

  const grades = JSON.parse(localStorage.getItem("grades"));
  const handleTotalPriceChange = (newPrice) => {
    setTotalPriceFromPanel(newPrice);
  };



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        OMT Form
      </DialogTitle>
      <DialogContent
        dir="rtl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <p>
          {Tr(
            "أرسل المبلغ مع 1$ عمولة عبر تطبيق OMT أو من أي مركز OMT  الى الرقم:"
          )}{" "}
          <span className="m-1 font-bold text-blue-700">71299280</span>
        </p>
        <p>
            {Tr("بعد دفع المبلغ أرسل صورة عن الوصل للرقم 71299280 و بذات اليوم نرسل لك كود الإشتراك مع كيفية تفعيله")}
          </p>
        <div className="w-full my-2">
        <GradeDropdownPanel
            grades={grades}
            offers={offers}
            onTotalPriceChange={handleTotalPriceChange}
          />
          <p>
            {Tr("Enter the number of needed cards to know the total price")}
          </p>
        </div>
      </DialogContent>
      <div dir="rtl" className="px-2">
        <p className="font-bold mr-6"> Price: ${totalPriceFromPanel.toFixed(2)}</p>
      </div>
      <DialogActions
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          p: 3,
          z: 100,
        }}
      >
        <Button
          type="button"
          color="error"
          onClick={handleClose}
          variant="contained"
          sx={{ zIndex: 200, ml: 2 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OMTPaymentModal;
