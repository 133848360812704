import "./GetCardButton.css";
import Tr from "../translation/Utils";
// import Swal from "sweetalert2";
// import { useTranslation } from "../translationUtils";
// import { useMediaQuery } from "react-responsive";
// import GetCardModal from "./PaymentMethod";
// import { useState } from "react";
import { useHistory } from "react-router-dom";

const GetCardButton = () => {
  const history = useHistory();
  // const { getTranslatedWord } = useTranslation();
  // const wp_number = localStorage.getItem("owner-wp-number");
  // const formattedPhoneNumber = wp_number?.replace(/[\s+]/g, "");
  // const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  // const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    history.push("/payment-method");
    // setOpenModal((o) => !o);
    // if (isMobile) {
    //   const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`;
    //   window.open(whatsappUrl, "_blank");
    // } else {
    //   Swal.fire({
    //     title: getTranslatedWord(" "),
    //     text: getTranslatedWord(
    //       "تواصل على الرقم 71299280 و بطاقة الإشتراك سنوصلها إلى منزلك"
    //     ),
    //   });
    // }
  };
  return (
    <>
    <div className="!flex !flex-col !w-[90%] sm:!w-[75%] mt-[0.5rem] sm:mt-10 sm:!my-8 sm:!h-[40px] h-[40px]">
  {/* Hidden on small screens */}


  {/* Button should be displayed nicely on all screen sizes */}
  <div className="!relative !grid !place-items-center !mb-[1rem]">
    <div className="!flex !items-center !justify-center !absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !bg-red-500 !rounded-xl sm:!w-[250px] sm:!h-[70px] w-[180px] h-[50px]">
      <button
        onClick={handleClick}
        className="!bg-[#0150fd] !rounded-xl !shadow-inner !shadow-white sm:!text-[22px] text-[16px] !font-bold w-[97%] h-[88%] px-4 py-2 text-white"
      >
        !أطلب بطاقتك الآن
      </button>
    </div>
  </div>
</div>
    </>
  );
};

export default GetCardButton;
